const logo = require('../assets/FL2F-logo.webp');

export const Preworkshopform = {
    "showPreviewBeforeComplete": "showAnsweredQuestions",
    "title": "Pre-Workshop Survey",
    "description": "Please fill out this survey before the workshop begins.",
    "logo": logo,
    "logoWidth": "auto",
    "logoHeight":"80px",
    "logoFit":"contain",
    "logoPosition":"right",
    "pages" : [
        {
        "navegationTitle": "Post-Workshop Survey",
        "elements": 
        [

            {
                "type":"panel",
                "name":"market_panel",
                "elements":[
                    {
                        "type": "matrix",
                        "name": "1_able_to_assess_market_needs",
                        "title": "I am able to access market needs and opportunities",
                        "isRequired": true,
                        "columns": [
                            {
                                "value": 1,
                                "text": "Did not make any progress"
                            }, {
                                "value": 2,
                                "text": "Planning to start soon"
                            }, {
                                "value": 3,
                                "text": "Started promotion"
                            }, {
                                "value": 4,
                                "text": "Made progress"
                            }, {
                                "value": 5,
                                "text": "Made significant progress"
                            }
                        ],
                        "rows": [
                            {
                                "value": "answer",
                                "text": "Please select"
                            }
                        ],
                    },
                ]
            },
            {
                "type":"panel",
                "name":"aware_panel",
                "elements":[
                    {
                        "type": "matrix",
                        "name": "2_bias_aware_Adaptive",
                        "title": "I am aware of my biases and I am adaptive to change",
                        "isRequired": true,
                        "columns": [
                            {
                                "value": 1,
                                "text": "Did not make any progress"
                            }, {
                                "value": 2,
                                "text": "Planning to start soon"
                            }, {
                                "value": 3,
                                "text": "Started promotion"
                            }, {
                                "value": 4,
                                "text": "Made progress"
                            }, {
                                "value": 5,
                                "text": "Made significant progress"
                            }
                        ],
                        "rows": [
                            {
                                "value": "answer",
                                "text": "Please select"
                            }
                        ],
                    },
                ]
            },
            {
                "type":"panel",
                "name":"creative_panel",
                "elements":[
                    {
                        "type": "matrix",
                        "name": "3_fostering_creative_thinking",
                        "title": "I am fostering creative thinking and innovation in my team",
                        "isRequired": true,
                        "columns": [
                            {
                                "value": 1,
                                "text": "Did not make any progress"
                            }, {
                                "value": 2,
                                "text": "Planning to start soon"
                            }, {
                                "value": 3,
                                "text": "Started promotion"
                            }, {
                                "value": 4,
                                "text": "Made progress"
                            }, {
                                "value": 5,
                                "text": "Made significant progress"
                            }
                        ],
                        "rows": [
                            {
                                "value": "answer",
                                "text": "Please select"
                            }
                        ],
                    },
                ]
            },
            {
                "type":"panel",
                "name":"knowledgeable_panel",
                "elements":[
                    {
                        "type": "matrix",
                        "name": "4_IP_knowledgeable",
                        "title": "I am knowledgeable about intellectual property and its role in business",
                        "isRequired": true,
                        "columns": [
                            {
                                "value": 1,
                                "text": "Did not make any progress"
                            }, {
                                "value": 2,
                                "text": "Planning to start soon"
                            }, {
                                "value": 3,
                                "text": "Started promotion"
                            }, {
                                "value": 4,
                                "text": "Made progress"
                            }, {
                                "value": 5,
                                "text": "Made significant progress"
                            }
                        ],
                        "rows": [
                            {
                                "value": "answer",
                                "text": "Please select"
                            }
                        ],
                    },
                ]
            },
            {
                "type":"panel",
                "name":"communicator_panel",
                "elements":[
                    {
                        "type": "matrix",
                        "name": "5_good_communicator",
                        "title": "I am a good communicator and can effectively pitch my ideas to others",
                        "isRequired": true,
                        "columns": [
                            {
                                "value": 1,
                                "text": "Did not make any progress"
                            }, {
                                "value": 2,
                                "text": "Planning to start soon"
                            }, {
                                "value": 3,
                                "text": "Started promotion"
                            }, {
                                "value": 4,
                                "text": "Made progress"
                            }, {
                                "value": 5,
                                "text": "Made significant progress"
                            }
                        ],
                        "rows": [
                            {
                                "value": "answer",
                                "text": "Please select"
                            }
                        ],
                    },
                ]
            },
            {
                "type":"panel",
                "name":"presenter_panel",
                "elements":[
                    {
                        "type": "matrix",
                        "name": "6_presenter_self_promoter",
                        "title": "I am a good presenter and self-promoter",
                        "isRequired": true,
                        "columns": [
                            {
                                "value": 1,
                                "text": "Did not make any progress"
                            }, {
                                "value": 2,
                                "text": "Planning to start soon"
                            }, {
                                "value": 3,
                                "text": "Started promotion"
                            }, {
                                "value": 4,
                                "text": "Made progress"
                            }, {
                                "value": 5,
                                "text": "Made significant progress"
                            }
                        ],
                        "rows": [
                            {
                                "value": "answer",
                                "text": "Please select"
                            }
                        ],
                    },
                ]
            },
            {
                "type":"panel",
                "name":"negotiator_panel",
                "elements":[
                    {
                        "type": "matrix",
                        "name": "7_good_negotiator",
                        "title": "I am a good negotiator and can close deals",
                        "isRequired": true,
                        "columns": [
                            {
                                "value": 1,
                                "text": "Did not make any progress"
                            }, {
                                "value": 2,
                                "text": "Planning to start soon"
                            }, {
                                "value": 3,
                                "text": "Started promotion"
                            }, {
                                "value": 4,
                                "text": "Made progress"
                            }, {
                                "value": 5,
                                "text": "Made significant progress"
                            }
                        ],
                        "rows": [
                            {
                                "value": "answer",
                                "text": "Please select"
                            }
                        ],
                    },
                ]
            },
            {
                "type":"panel",
                "name":"negotiator_panel",
                "elements":[
                    {
                        "type": "matrix",
                        "name": "8_resilient",
                        "title": "I am resilient and can bounce back from setbacks",
                        "isRequired": true,
                        "columns": [
                            {
                                "value": 1,
                                "text": "Did not make any progress"
                            }, {
                                "value": 2,
                                "text": "Planning to start soon"
                            }, {
                                "value": 3,
                                "text": "Started promotion"
                            }, {
                                "value": 4,
                                "text": "Made progress"
                            }, {
                                "value": 5,
                                "text": "Made significant progress"
                            }
                        ],
                        "rows": [
                            {
                                "value": "answer",
                                "text": "Please select"
                            }
                        ],
                    },
                ]
            },
            {
                "type":"panel",
                "name":"strategic_panel",
                "elements":[
                    {
                        "type": "matrix",
                        "name": "9_strategic_planner",
                        "title": "I am a strategic planner and can create a business plan",
                        "isRequired": true,
                        "columns": [
                            {
                                "value": 1,
                                "text": "Did not make any progress"
                            }, {
                                "value": 2,
                                "text": "Planning to start soon"
                            }, {
                                "value": 3,
                                "text": "Started promotion"
                            }, {
                                "value": 4,
                                "text": "Made progress"
                            }, {
                                "value": 5,
                                "text": "Made significant progress"
                            }
                        ],
                        "rows": [
                            {
                                "value": "answer",
                                "text": "Please select"
                            }
                        ],
                    },
                ]
            },
            {
                "type":"panel",
                "name":"alliance_panel",
                "elements":[
                    {
                        "type": "matrix",
                        "name": "10_alliance_builder",
                        "title": "I am an alliance builder and can create strategic partnerships",
                        "isRequired": true,
                        "columns": [
                            {
                                "value": 1,
                                "text": "Did not make any progress"
                            }, {
                                "value": 2,
                                "text": "Planning to start soon"
                            }, {
                                "value": 3,
                                "text": "Started promotion"
                            }, {
                                "value": 4,
                                "text": "Made progress"
                            }, {
                                "value": 5,
                                "text": "Made significant progress"
                            }
                        ],
                        "rows": [
                            {
                                "value": "answer",
                                "text": "Please select"
                            }
                        ],
                    },
                ]
            },
        ],
    }],
    "showQuestionNumbers": false,
};