

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { theme } from '../components/theme';
import { getMember } from '../features/members/memberSlice';
import { postFollowup } from '../features/survey/surveySlice';
import { Followupform } from '../surveys/FollowupForm';


function Followup() {
    const dispatch = useDispatch();
    const { id } = useParams();

    const { member, isLoading } = useSelector((state) => state.members);

    useEffect(() => {
        dispatch(getMember(id));
    }, [dispatch, id]);

    const survey = new Model(Followupform);

    survey.applyTheme(theme);

    if(!member || Object.keys(member).length === 0) {
        survey.completedHtml = "<h3>There was an error loading the survey. Please try again later.</h3>"; 
        survey.doComplete(); //sets the survey as completed
    } else if (member.followup === 1) {
        survey.doComplete(); //sets the survey as completed
    } else {
        survey.onComplete.add(async (sender, options) =>{
            options.showSaveInProgress();
            const data = {
                id: id,
                ...sender.data,
            };
            try {
                const resultAction = await dispatch(postFollowup(data)).unwrap();
                options.showSaveSuccess();
            } catch (error) {
                console.error(error);
                options.showSaveError();
            }
        });
    }

    return (
        <div>
            {isLoading ? (
                <Loader size="huge" active content="Loading survey..." />
            ) : (
                <Survey model={survey} />
            )}
        </div>
    );
}


export default Followup